export default {
  methods: {
    cloneObjectDeep(obj) {
      return JSON.parse(JSON.stringify(obj))
    },

    cloneObjectDeepWithLocales(obj, locales) {
      const localesObject = Object.fromEntries(locales.map((l) => [l.value, '']))
      const entries = Object.entries(JSON.parse(JSON.stringify(obj)))
      const clone = entries.map(([key, value]) => (value === '@LOCALE' ? [key, { ...localesObject }] : [key, value]))
      return Object.fromEntries(clone)
    },
  },
}
