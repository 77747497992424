<template>
  <v-tooltip top nudge-right="2">
    <template v-slot:activator="{ on, attr }">
      <v-btn class="mr-2" color="primary" outlined x-small fab @click="$router.back()" v-bind="attr" v-on="on">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </template>
    {{ `Назад` }}
  </v-tooltip>
</template>

<script>
export default {
  name: 'BackButton',
}
</script>
