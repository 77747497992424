/* *** Merging two objects while keeping matching keys *** remove redundant keys *** merge root */
export function mergeObjects(targetObj, obj) {
  return Object.assign({}, ...Object.keys(targetObj).map((k) => ({ [k]: obj[k] })))
}

function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item)
}

/* deep recursive merge */
export function mergeObjectsDeep(target, ...sources) {
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in target) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        mergeObjectsDeep(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }
  return mergeObjectsDeep(target, ...sources)
}
