export default {
  data: () => ({
    isComponentRerendered: true,
  }),

  methods: {
    async forceRerender() {
      this.isComponentRerendered = false
      await this.$nextTick()
      this.isComponentRerendered = true
    },
  },
}
