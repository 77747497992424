<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="allDashboards"
        :loading="loading"
        :options.sync="options"
        :server-items-length="getDashboardsTotalCount"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar color="indigo lighten-5" flat>
            <BackButton />
            <v-toolbar-title>Дашборди</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small v-bind="attrs" v-on="on" disabled> Додати дашборд </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <div class="text-h5">
                    {{ formTitle }}
                    <span v-if="editedItem.id">(ID{{ editedItem.id }})</span>
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Назва"
                          @input="$v.editedItem.name.$touch()"
                          @blur="$v.editedItem.name.$touch()"
                          :error-messages="nameErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.uid"
                          label="UID"
                          @input="$v.editedItem.uid.$touch()"
                          @blur="$v.editedItem.uid.$touch()"
                          :error-messages="uidErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-switch
                          v-model="editedItem.is_active"
                          :label="editedItem.is_active ? 'Активний' : 'Прихований'"
                          color="success"
                          dense
                          hide-details
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="save()">Так</v-btn>
                  <v-btn text @click="close">Нi</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.is_active="{ item }">
          <CircleIndicator :is-on="item.is_active" />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil-outline </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="resetData">Оновити</v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { DASHBOARD_SCHEMA } from '@/const/apiSchemas'
import { mergeObjectsDeep } from '@/helpers/mergeObjects'
import cloneDeep from '@/mixins/cloneDeep'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import resetTableData from '@/mixins/resetTableData'
import ForceRerender from '@/mixins/forceRerender'
import BackButton from '@/components/common/BackButton'
import CircleIndicator from '@/components/common/CircleIndicator'

export default {
  name: 'DashboardConstructorList',

  components: {
    BackButton,
    CircleIndicator,
  },

  mixins: [validationMixin, resetTableData, cloneDeep, ForceRerender],

  validations: {
    editedItem: {
      name: { required },
      uid: { required },
    },
  },

  data: () => ({
    DASHBOARD_SCHEMA: DASHBOARD_SCHEMA,
    dialog: false,
    loading: false,
    isUserLoading: false,
    headers: [
      { text: 'ID', align: 'start', sortable: true, value: 'id' },
      { text: 'Назва', value: 'name', sortable: true },
      { text: 'UID', value: 'uid', sortable: false },
      { text: 'Статус', align: 'center', value: 'is_active', sortable: false },
      { text: '', value: 'actions', sortable: false, align: 'center' },
    ],
    dashboardsList: [],
    editedIndex: -1,
    sortBy: '',
    sortDesc: false,
    options: {},
    editedItem: {},
    defaultItem: {},
  }),

  computed: {
    ...mapState('dashboard', ['allDashboards']),
    ...mapGetters('dashboard', ['getDashboardsTotalCount']),

    formTitle() {
      return this.editedIndex === -1 ? 'Створити дашборд' : 'Редагувати дашборд'
    },
    sortDirection() {
      if (this.options.sortBy.length === 1 && this.options.sortDesc.length === 1) {
        return this.options.sortDesc[0] ? 'desc' : 'asc'
      } else return ''
    },
    nameErrors() {
      const errors = []
      if (!this.$v.editedItem.name.$dirty) return errors
      !this.$v.editedItem.name.required && errors.push('Це поле обов"язкове')
      return errors
    },
    uidErrors() {
      const errors = []
      if (!this.$v.editedItem.uid.$dirty) return errors
      !this.$v.editedItem.uid.required && errors.push('Це поле обов"язкове')
      return errors
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    options: {
      handler() {
        this.paginateTo()
      },
      deep: true,
    },
  },

  mounted() {
    this.clone()
  },

  methods: {
    ...mapActions('dashboard', ['loadAllDashboards', 'updateSelectedDashboard']),

    clone() {
      this.editedItem = { ...this.cloneObjectDeep(DASHBOARD_SCHEMA) }
      this.defaultItem = { ...this.cloneObjectDeep(DASHBOARD_SCHEMA) }
    },

    initialize() {
      this.dashboardsList = [...this.allDashboards]
    },

    async paginateTo() {
      this.loading = true
      const payload = {
        page: this.options.page,
        limit: this.options.itemsPerPage,
        sort: this.options.sortBy[0],
        direction: this.sortDirection,
      }
      await this.loadAllDashboards(payload)
      this.initialize()
      this.loading = false
    },

    editItem(item) {
      this.editedIndex = this.dashboardsList.indexOf(item)
      this.editedItem = mergeObjectsDeep(this.editedItem, item)
      this.forceRerender()
      this.dialog = true
    },

    close() {
      this.dialog = false
      this.$v.editedItem.$reset()
      this.$nextTick(() => {
        this.clone()
        this.editedIndex = -1
      })
    },

    async save() {
      this.$v.editedItem.$touch()
      if (this.$v.$anyError) return
      this.close()
      this.loading = true
      if (this.editedIndex > -1) {
        const payload = {
          id: this.editedItem.id,
          updatedDashboard: this.editedItem,
        }
        delete payload.updatedDashboard.id
        await this.updateSelectedDashboard(payload)
      }
      await this.paginateTo()
    },
  },
}
</script>

<style scoped></style>
